

















































import {Component, Vue, Prop} from "vue-property-decorator";
import {Event} from "@/components/Models";
import {RainbowDirective} from "@/components/RainbowDirective";
import * as _ from "lodash";

@Component({
  directives: {'rainbow': RainbowDirective}
})
export default class AccessPointsEvents extends Vue {
  @Prop({type: Array, required: true, default: []})
  readonly liveEvents!: Event[]

  get events() {
    return this.liveEvents;
  }

  renderEventData(data: any) {
    return _.filter(_.zip(Object.keys(data), Object.values(data)).map((pair) => ({
      key: pair[0], value: pair[1]
    })), (item) => !_.isEmpty(item.value))
  }

  isObject(what: any): boolean {
    return _.isObject(what)
  }
}
