
















import {Component, Vue} from 'vue-property-decorator';
import LiveView from './components/LiveView.vue';

@Component({
  components: {LiveView},
})
export default class App extends Vue {
}
