
















































import {Component, Prop, Vue} from "vue-property-decorator";
import {LocationMeasurement} from "@/components/Models";
import {RainbowDirective} from "@/components/RainbowDirective";
import Clients from "@/components/Clients.vue";

@Component({
  components: {Clients},
  directives: {'rainbow': RainbowDirective}
})
export default class AccessPointsFeed extends Vue {
  @Prop({type: Array, required: true, default: []})
  readonly liveMeasurements!: LocationMeasurement[]

  get measurements(): LocationMeasurement[] {
    return this.liveMeasurements;
  }
}
