








import {Component, Vue} from "vue-property-decorator";

@Component({
  name: 'LiveToggle'
})
export default class LiveToggleComponents extends Vue {
  private live = true;

  get isLive() {
    return this.live;
  }

  toggleLive() {
    this.live = !this.live;
    this.$emit(this.live ? 'live' : 'pause', [this.live]);
  }
}
