


















import {Component, Prop, Vue} from "vue-property-decorator";
import {Client} from "@/components/Models";
import {RainbowDirective} from "@/components/RainbowDirective";

@Component({
  name: 'Clients',
  directives: {'rainbow': RainbowDirective}
})
export default class Clients extends Vue {
  @Prop({type: Array, required: true, default: []})
  readonly clients!: Client[]

  get clientsList(): Client[] {
    return this.clients;
  }
}
